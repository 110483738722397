import React from 'react'

const PageHeader = (props) => {

    const { content, head } = props

    const renderHead = () => {
        const title = content && content.field_title ? content.field_title : ''
        switch (head) {
            case 2:
                return <h2>{title}</h2>

            case 3:
                return <h3>{title}</h3>
            default:
                break;
        }
    }

    return (
        <>
            { renderHead() }
            <div dangerouslySetInnerHTML={{ __html: content?.text?.processed ? content.text.processed : '' }}></div>
            <div dangerouslySetInnerHTML={{ __html: content?.htmlText?.processed ? content.htmlText.processed : '' }}></div>
        </>
    )
}

export default PageHeader
