import React, { useState } from "react"

import ImageComponent from "../imageComponent"
import ParagraphText from "../paragraphText"
import ParagraphInset from "../paragraphInset"

import { slugify, objectDeepClone } from "../../../service/helper"

const ParagraphTabs = (props) => {

    const { content } = props

    const tabTitles = content.map(el => {
        return {
            title: el.title,
            id: el.id
        }
    })

    const defaultImage =  "https://via.placeholder.com/481x278"

    let [activeTab, setActiveTab] = useState(content && content.length ? slugify(content[0].title) : '')

    return (
        <div className="container aboutContainer">
            <ul className="nav nav-tabs gallery-tabs" role="tablist">
                {
                    tabTitles.map((el, index) => {
                        return (
                            <li key={index} role="presentation" className="nav-item">
                                <a
                                    onClick={() => setActiveTab(slugify(el.title))}
                                    className={`nav-link ${activeTab === slugify(el.title) ? 'active' : ''}`}
                                    data-toggle="tab" href={`#${slugify(el.title)}`} role="tab" aria-controls={`${slugify(el.title)}`} aria-selected="false">
                                    {el.title}</a>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="container">
                <div className="tab-content mt-3">
                {
                    content.map((el, index) => {
                        const components = (el.relationships && el.relationships.components && el.relationships.components.length) ? el.relationships.components : []
                        let imgObj = {}
                        let altText = ""
                        if (components && components.length > 0) {
                            imgObj = components[0]?.relationships?.image?.uri?.url
                            altText = el?.image?.alt || "Hinduja Hospital"
                        }
                        return (
                            <div key={index} className={`tab-pane ${activeTab === slugify(el.title) ? 'active' : ''}`} id={slugify(el.title)} role="tabpanel">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <figure>
                                                {
                                                    imgObj ?
                                                    <img
                                                        src={imgObj}
                                                        alt={altText}
                                                    />
                                                    : <img className="br-20 ls-is-cached lazyloaded" src={defaultImage} alt={altText} />
                                                }
                                            </figure>
                                        </div>
                                        <div className="col-md-6 para_text">
                                            <ParagraphText
                                                content={components && components.length ? components[1] : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                (components && components.length > 2) &&
                                                objectDeepClone(components).splice(2, components.length - 1).map((el, index) => {
                                                    if (el['__typename'] === 'paragraph__html_text') {
                                                        return (
                                                            <ParagraphText
                                                                key={index}
                                                                content={el}
                                                            />
                                                        )
                                                    } else if (el['__typename'] === 'paragraph__inset') {
                                                        return (
                                                            <ParagraphInset
                                                                key={index}
                                                                content={el}
                                                                classNames=" blue_band "
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default ParagraphTabs
