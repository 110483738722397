import React from 'react'

import QuoteLeft from "../../../../static/assets/images/icons/quote_left.svg"
import QuoteRight from "../../../../static/assets/images/icons/quote_right.svg"

const quotedText = (props) => {

    const { content } = props
    const pre = content && content?.pre ? content.pre : ''
    const quote = content && content?.quote ? content.quote : ''
    const post = content && content?.post ? content.post : ''
    return (
        <div className="grey_box">
            <p>{pre}</p>
            <div className="quote_section">
                    <p className="quote">
                        <span className="quote_icon left">
                            <img className="ls-is-cached lazyloaded" src={QuoteLeft} alt="quoteLeft" />
                        </span>
                            {quote}
                        <span className="quote_icon right">
                            <img className=" ls-is-cached lazyloaded" src={QuoteRight} alt="quoteRight" />
                        </span>
                    </p>
            </div>
            <p className="last_para">{post}</p>
        </div>
    )
}

export default quotedText
