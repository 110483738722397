import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

const PageHeader = (props) => {

    const { image, relationships, classNames } = props.content
    const altText = image && image.alt ? image.alt : 'Hinduja Hospital'

    const imgSrc = relationships?.image?.uri?.url ?
                    relationships.image.uri?.url : null
    return (
        <>
            {imgSrc ?
                <img className={`lazyload ${classNames ? classNames : ''}`} 
                    src={imgSrc}
                    alt={altText}
                />
            : null
            }
        </>
    )
}

export default PageHeader
