import React from 'react'

import QuoteLeft from "../../../../static/assets/images/icons/quote_left.svg"
import QuoteRight from "../../../../static/assets/images/icons/quote_right.svg"

const quotedText = (props) => {

    const { content } = props
    return (
        <p className="quote">
        <span className="quote_icon
            left"><img className="lazyload" 
            src={QuoteLeft}
            alt="" /></span>
            {content}
        <span className="quote_icon
            right"><img className="lazyload" 
            src={QuoteRight}
            alt="" /></span>
        </p>
    )
}

export default quotedText
